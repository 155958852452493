/** Media Queries **/

@mixin littlePhone {
    @media (max-width: #{$littlePhone}) {
        @content;
    }
}

@mixin phone {
    @media (max-width: #{$phone}) {
        @content;
    }
}

@mixin tablet {
    @media (max-width: #{$tablet}) {
        @content;
    }
}

@mixin notebook {
    @media (max-width: #{$notebook}) {
        @content;
    }
}
  
@mixin desktop {
    @media (max-width: #{$desktop}) {
        @content;
    }
}

@mixin response($maxWidth) {
    @media (max-width: #{$maxWidth}) {
        @content;
    }
}

@mixin grid($columns, $space ) {
    display: grid;
    gap: $space;
    grid-template-columns: repeat($columns, 1fr);
}

// ===================================================



/** Media Queries **/

@mixin xs {
    @media (max-width: #{$xs}) {
        @content;
    }
}

@mixin sm {
    @media (max-width: #{$sm}) {
        @content;
    }
}

@mixin md {
    @media (max-width: #{$md})  {
        @content;
    }
}

@mixin lg {
    @media (max-width: #{$lg}) {
        @content;
    }
}
  
@mixin xl {
    @media (max-width: #{$xl}) {
        @content;
    }
}

@mixin xxl {
    @media (max-width: #{$xxl}) {
        @content;
    }
}

// ===============================

@mixin xs-h {
    @media (max-height: #{$xs}) {
        @content;
    }
}

@mixin sm-h {
    @media (max-height: #{$sm}) {
        @content;
    }
}

@mixin md-h {
    @media (max-height: #{$md})  {
        @content;
    }
}

@mixin lg-h {
    @media (max-height: #{$lg}) {
        @content;
    }
}
  
@mixin xl-h {
    @media (max-height: #{$xl}) {
        @content;
    }
}

@mixin xxl-h {
    @media (max-height: #{$xxl}) {
        @content;
    }
}