
.button__floatButton {
    width: 55px;
    height: 55px;
    border-radius: 50px;
    background: white;
    position: fixed;
    right: 50px;
    bottom: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 5;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.5);

    & > img {
        width: 30px;
        height: 30px;
        padding-bottom: 2px;
    }

    @include md {
        right: 30px;
    }

}

.button__homeFloatButton {
    width: 55px;
    height: 55px;
    border-radius: 50px;
    background: #F8F9FA;
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 4;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.25);

    & > img {
        width: 30px;
        height: 30px;
    }

}